/* @import url('fonts/GTFlexa-Bold.otf'); */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GTFlexa-Regular';
  src: url('fonts/GTFlexa-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GTFlexa-Medium';
  src: url('fonts/GTFlexa-Medium.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GTFlexa-Bold';
  src: url('fonts/GTFlexa-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

@layer base {
  a {
    @apply text-accent;
  }
}

@layer utilities {
  /* hide scrollbar for chrome and safari */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for internet explorer, microsoft edge and firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* internet explorer and microsoft edge */
    scrollbar-width: none; /* firefox */
  }
}

:root {
  --100vh: calc(var(--vh, 1vh) * 100);
  --pvh: 0px;
  --2pvh: calc(2 * var(--pvh));
  --app-h: calc(var(--100vh) - var(--2pvh));
  --app-w: 100vw;
  --app-padding: 24px;
  --app-minus-padding-w: calc(var(--app-w) - var(--app-padding));
}

@media (min-width: 768px) {
  :root {
    --pvh: 48px;
    --app-w: 768px;
  }
}

#__next {
  background: radial-gradient(#2cc26f, #01695a);
  backdrop-filter: blur(4px);
  height: calc(var(--100vh));
  width: 100vw;
}

h1 {
  font-family: 'GTFlexa-Bold', 'sans-serif';
}

h2,
h3,
h4 {
  font-family: 'GTFlexa-Regular', 'sans-serif';
}

p {
  font-family: 'GTFlexa-Regular', 'sans-serif';
}

body {
  font-family: 'GTFlexa-Regular', sans-serif;
  overflow: hidden;
  height: calc(var(--100vh));
  width: 100vw;
}

/* 
  custom styling for the daisyui range component step indicator
  -------------------------------------------------------------
  the following css rule targets the span elements within a div, 
  immediately following any element with a 'range' class.
  
  purpose:
  - the original Range component from react-daisyui renders vertical lines ("|")
    as visual step indicators below the range input.
  - the default appearance doesn't align with our current designs, and when there are many
    steps (e.g. for 'per hour'), they overflow from their container, looking not-so-pleasant.
    
  implementation:
  - 'display: none;' hides all step indicators for a cleaner visual appearance
    of the range slider.

  ref: 
  - react-daisyui Range component: https://github.com/daisyui/react-daisyui/blob/main/src/Range/Range.tsx
*/
.range + div span {
  display: none;
}
